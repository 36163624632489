import { API_ROOT, getVendorAddressesForContractUrl } from '../api_endpoints';
import { acquireTokenPopupAndCallAPI, fetchWithToken } from '../authentication';

const GOT_VENDORS = 'GOT_VENDORS';
const GOT_SINGLE_VENDOR = "GOT_SINGLE_VENDOR";
const GOT_CONTRACT_VENDOR_ADDRESSES = 'GOT_CONTRACT_VENDOR_ADDRESSES';

export const getVendors = () => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        API_ROOT.vendors,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    ));
    const vendors = await response.json();
    dispatch(gotVendors(vendors));
  }
  catch (error) {
    console.error(error);
  }
}

const gotVendors = (vendors) => ({
  type: GOT_VENDORS,
  vendors,
});

export const getSingleVendor = vendorId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${API_ROOT.vendors}/${vendorId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ));
    const singleVendor = await response.json();
    dispatch(
      gotSingleVendor(singleVendor)
    );
  } catch (error) {
    console.error(error);
  }
};

export const getSingleVendorByContract = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${API_ROOT.vendors}/ByContract/${contractId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ));
    const singleVendor = await response.json();
    dispatch(
      gotSingleVendor(singleVendor)
    );
  } catch (error) {
    console.error(error);
  }
};

const gotSingleVendor = singleVendor => ({
  type: GOT_SINGLE_VENDOR,
  singleVendor
});

const gotContractVendorAddresses = contractVendorAddresses => ({
  type: GOT_CONTRACT_VENDOR_ADDRESSES,
  contractVendorAddresses
});

export const getContractVendorAddresses = contractId => async dispatch => {
  try {
    const response = await fetchWithToken(getVendorAddressesForContractUrl(contractId));
    const data = await response.json();
    dispatch(gotContractVendorAddresses(data.subVendorAddresses));
  }
  catch (error) {
    console.error(error);
  }
}

const initialState = {
  vendors: [],
  singleVendor: null,
  contractVendorAddresses: []
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_VENDORS:
      return { vendors: action.vendors };
    case GOT_SINGLE_VENDOR: {
      const { singleVendor } = action;
      return { ...state, singleVendor };
    }
    case GOT_CONTRACT_VENDOR_ADDRESSES: {
      const { contractVendorAddresses } = action;
      return { ...state, contractVendorAddresses };
    }
    default:
      return state;
  }
}
