export const API_ROOT = {
  contract: "api/Contracts",
  facility: "api/Facility",
  allFacility: "api/Facility/all",
  user: "api/UserInfo",
  role: "api/Role",
  financialSummary: "api/Contracts/FinancialSummary",
  reauthorizations: "api/Contracts/Reauthorizations",
  editContractStatus: "api/Contracts",
  pdpsstaffcostactualbudget: "api/PDPSStaffCostActualBudget",
  vendors: "api/vendors",
  milestones: "api/MileStones",
  audits: "api/AuditInfo",
  captrakIds: "api/CapTraks",
  chargeCodes: "api/ChargeCodes",
  chargeCodesByContract: "api/ChargeCodes/ByContract",
  //adjustmentByChargecode: "api/ChangeorderDetails/AdjustmentByChargecode",
  AdjustmentByChargecode: "api/ChangeorderDetails/AdjustmentByChargecode",

  PaymentAccruals: "api/ChargeCodes/PendingPaymentAccrualsByChargeCode",
  WorkOrderDetailsPaid: "api/WorkorderDetails/WorkorderDetailsPaymentPaid",

  classified: "api/Classified",
  classifieds: "api/Classified/ByContract",
  classifiedPaymentByClassifiedWo: "api/Classified/Payment/ByClassifiedWo",
  classifiedPaymentByContractId: "api/Classified/Payment/ByContractId",
  classifiedDetail: "api/ClassifiedDetails",
  classifiedDetails: "api/ClassifiedDetails/ByClassified",
  classifiedDetailWO: "api/ClassifiedDetails/ByClassifiedWO",
  classifiedWO: "api/ClassifiedWO",
  classifiedWOs: "api/ClassifiedWO/ByContract",
  classifiedWOByNetCost: "api/ClassifiedWO/ByNetCost",
  unclassified: "api/Lumpsums",
  unclassifieds: "api/Lumpsums/ByContract",
  unclassifiedPaymentByContractId: "api/Lumpsums/Payment/ByContractId",    
  unclassifiedDeductions: "api/Lumpsums/Deductions",
  performbondPaymentByContractId: "api/Performbond/Payment/ByContractId", // does not exit. check if needs to be used.
  contractvendor: "api/ContractVendor",
  contractvendors: "api/ContractVendor/ByContract",
  contractvendorpayment: "api/ContractVendorPayments",
  contractvendorpayments: "api/ContractVendorPayments/ByContractVendor",
  netcost: "api/Netcosts",
  netcosts: "api/Netcosts/ByContract",  
  schedule: "api/Schedule",
  schedules: "api/Schedule/ByContract",
  netcostPaymentByClassifiedWo: "api/Netcosts/Payment/ByClassifiedWo",
  netcostPaymentByContractId: "api/Netcosts/Payment/ByContractId",
  netcostDetail: "api/NetcostDetails",
  netcostDetails: "api/NetcostDetails/ByNetcost",
  closeDelayCodes: "api/DelayCodes",
  workOrders: "api/Workorders",
  activityTasks: "api/WorkorderDetails",
  unitPriceByCategory: "api/WOunitpriceitemDetails/ByParm",
  unitPriceByUpNo: "api/WOunitpriceitemDetails/ByWorkOrderDetailandUpno",
  unitPrice: "api/WOunitpriceitemDetails",
  unitPriceByActivityTask: "api/WOunitpriceitemDetails/ByWorkOrderDetail",
  referenceData: "/api/ReferenceData",
  referenceDataByCategory: (category) =>
    `api/ReferenceData/ByCategory/${category}`,
  pins: "api/Pins/ByContract",
  paccpins: "api/Pins/ByPacc",
  pin: "api/Pins",
  pinDetail: "api/PinDetails",
  pinDetails: "api/PinDetails/ByPin",
  paccs: "api/Pacc/ByContract",
  changeorderpacc: "api/Pacc/ByChangeorder",
  pacc: "api/Pacc",
  paccDetail: "api/PaccDetails",
  paccDetails: "api/PaccDetails/ByPacc",
  paccDetailWO: "api/PaccDetails/ByClassifiedWO",
  changeorder: "api/Changeorder",
  changeorders: "api/Changeorder/ByContract",
  changeorderPayment: "api/Changeorder/Payment/ByChangeorder",
  changeorderPaymentByClassifiedWo: "api/Changeorder/Payment/ByClassifiedWo",
  changeorderPaymentByContractId: "api/Changeorder/Payment/ByContractId",
  changeorderDetail: "api/ChangeorderDetails",
  changeorderDetails: "api/ChangeorderDetails/ByChangeorder",
  changeorderDetailWO: "api/ChangeorderDetails/ByClassifiedWO",
  fins: "api/Fins/ByContract",
  pafifins: "api/Fins/ByPafi",
  fin: "api/Fins",
  pafis: "api/Pafi/ByContract",
  fieldorderpafi: "api/Pafi/ByFieldorder",
  pafi: "api/Pafi",

  pafiDetail: "api/PafiDetails",
  pafiDetails: "api/PafiDetails/ByPafi",
  pafiDetailWO: "api/PafiDetails/ByClassifiedWO",

  fieldorder: "api/Fieldorder",
  fieldorders: "api/Fieldorder/ByContract",
  fieldorderPayment: "api/Fieldorder/Payment/ByFieldorder",
  fieldorderPaymentByClassifiedWo: "api/Fieldorder/Payment/ByClassifiedWo",
  fieldorderPaymentByContractId: "api/Fieldorder/Payment/ByContractId",
  fieldorderDetail: "api/FieldorderDetails",
  fieldorderDetails: "api/FieldorderDetails/ByFieldorder",
  fieldorderDetailWO: "api/FieldorderDetails/ByClassifiedWO",
  potentialdelay: "api/PotentialDelay",
  potentialdelays: "api/PotentialDelay/ByContract",
  rcpotentialdelays: "api/PotentialDelay/RC/ByContract",
  spread: "api/Spread",
  spreads: "api/Spread/ByContract",
  spreadCutoffDate: "api/Spread/ByContract/CutOffDate",
  spreaddetail: "api/SpreadDetail",
  spreaddetails: "api/SpreadDetail/ByContract",     
  spreadwipcclineitems: "api/SpreadDetail/SpreadWIPCCAndLineByContract",
  spreadwoccmapping: "api/SpreadDetail/SpreadWOAndCCMappingByContract",
  extraworkwip: "api/ExtraWorkWIP",
  extraworkwips: "api/ExtraWorkWIP/ByContract",
  fieldorderwip: "api/FieldOrderWIP",
  fieldorderwips: "api/FieldOrderWIP/ByContract",
  recycle: "api/Recycle",
  recycles: "api/Recycle/ByContract",
  recyclePlan: "api/RecyclePlan",
  recyclePlans: "api/RecyclePlan/ByContract",
  azureupload: "api/AzureUpload",
  azureBlobList: "api/AzureUpload/ListContainerBlob/",
  reportServerSetting: "api/Reports/getReportServerURL",
  azureDownload: (pipeSeparatedPath) =>
    `api/AzureUpload/DownloadBlob/${pipeSeparatedPath}`,
  azureBlobDelete: (pipeSeparatedPath) =>
    `api/AzureUpload/DeleteBlob/${pipeSeparatedPath}`,

  azureFileStorageCreateLib: "api/AzureFSUpload/CreateFileStorage",
  //azureFileStorageCreateLib: (contractNo) => `api/AzureFSUpload/CreateFileStorage/${contractNo}`,
  unitCost: "api/UnitCost",
  setTracked: (contractId, hyphenatedDate) =>
    `api/contracts/${contractId}/settracked?date=${hyphenatedDate}`,
  unitPriceByWorkOrderDetail: (workOrderDetailId) => {
    const prefix = "api/WorkOrderUnitPrice";
    if (workOrderDetailId) {
      return `${prefix}?workOrderDetailId=${workOrderDetailId}`;
    }
    return prefix;
  },
  unitPriceById: (id) => `api/WorkOrderUnitPrice/${id}`,
  unitPriceDetailsByUnitPriceId: (id) => `api/WorkOrderUnitPrice/${id}/details`,
  addUnitPrice: "api/WorkOrderUnitPrice",
  editUnitPrice: (id) => `api/WorkOrderUnitPrice/${id}`,
  unitPriceDetailById: (id) => `api/WOunitpriceitemDetails/${id}`,
  me: "api/userinfo/me",
  searchVendorByName: (nameSubstr = "", einSubstr = "") =>
    `api/vendors?name=${encodeURIComponent(nameSubstr)}&ein=${einSubstr}`,
  searchVendorAddressByVendorName: (nameSubstr = "") =>
    `api/vendors/VendorAddress?name=${encodeURIComponent(nameSubstr)}`,
  userExists: "api/userinfo/exists",
  supplementalByContractId: (contractId) =>
    `api/supplemental?contractId=${contractId}`,
  msGraphUsersByAdId: (aDId) =>
    `https://graph.microsoft.com/v1.0/users/${aDId}@panynj.gov`,
  supplementalById: (id) => `api/supplemental/${id}`,
  singleVendorById: (id) => `api/vendors/${id}`,
  vendorAddressesByVendorId: (vendorId) =>
    `api/vendoraddresses?vendorId=${vendorId}`,
  singleVendorAddress: (id) => `api/vendoraddresses/${id}`,
  vendorAddresses: "api/vendoraddresses",
  vendorMatrix: (id) => `api/ContractVendor/VendorMatrix/${id}`,
  addVendorWithAddress: "api/vendors/withaddress",
  goals: "api/Goals/ByContract",
};

export const vendorExists = (ein) => `api/vendors?ein=${ein}`;
export const makeVendorAddressPrimary = (id) =>
  `api/vendoraddresses/${id}/makeprimary`;
export const referenceDataByCategory = (category) =>
  `api/ReferenceData/ByCategory/${category}`;
export const setAsideVendor = (id) => `api/vendors/${id}/setaside`;
export const searchSetAsides = (nameSubstr, einSubstr, isActive) => {
  let url = `api/vendorsetasides?name=${encodeURIComponent(nameSubstr)}&ein=${einSubstr}`;
  if (isActive !== null) {
    url += `&active=${isActive}`;
  }
  return url;
};
export const setAsideById = (id) => `api/VendorSetasides/${id}`;
export const setAsideSetActive = (isActive, id) =>
  `api/VendorSetasides/${id}/SetActive?active=${String(isActive)}`;
export const searchVendorByName = (nameSubstr = "", einSubstr = "") =>
  `api/vendors?name=${encodeURIComponent(nameSubstr)}&ein=${einSubstr}`;
export const searchVendorAddressByVendorName = (nameSubstr = "") =>
  `api/vendors/VendorAddress?name=${encodeURIComponent(nameSubstr)}`;
export const getEditPerfBondUrl = (id) => `api/Contracts/${id}/PerfBond`;
export const getVendorAddressesForContractUrl = (contractId) =>
  `api/Contracts/${contractId}/VendorAddresses`;
export const getVendorAddressesAndEvalForContractUrl = (contractId) =>
  `api/Contracts/${contractId}/VendorAddressesWithEvaluations`;
export const getEvaluationByContractIdAndVendorAddressIdUrl = (
  contractId,
  vendorAddressId
) =>
  `api/VendorEvaluations/ByContractIdAndVendorAddressId?contractId=${contractId}&vendorAddressId=${vendorAddressId}`;
export const getEvaluationById = (id) => `api/VendorEvaluations/${id}`;
export const evaluationBaseUrl = "api/VendorEvaluations";
export const getSingleVendorAddressUrl = (id) => `api/vendoraddresses/${id}`;
export const getApproveEvaluationUrl = (id, interim) =>
  `api/VendorEvaluations/${id}/approve?interim=${String(interim)}`;
export const getPaymentsByContractIdUrl = (contractId) =>
  `api/payments?contractId=${contractId}`;
export const getPaymentByPaymentIdUrl = (paymentId) =>
  `api/payments/${paymentId}`;
export const addPaymentUrl = "api/payments";
export const editPaymentByPaymentIdUrl = (paymentId) =>
  `api/payments/${paymentId}`;
export const postPaymentByPaymentIdUrl = (paymentId) =>
  `api/payments/${paymentId}/post`;
export const unpostPaymentByPaymentIdUrl = (paymentId) =>
  `api/payments/${paymentId}/unpost`;
export const finalizePaymentByPaymentIdUrl = (paymentId) =>
  `api/payments/${paymentId}/finalize`;
export const editPaymentBondChargeCodeByPaymentIdUrl = (paymentId) =>
  `api/payments/${paymentId}/bondchargecode`;
export const paymentClassifiedBalanceUrl = (classifiedId, chargeCodeId, classifiedWOItemId, paymentId) =>
  `api/payments/classified/balance?classifiedId=${classifiedId}&chargeCodeId=${chargeCodeId}&classifiedWOItemId=${classifiedWOItemId}&paymentId=${paymentId}`;
export const addClassifiedPaymentUrl = "api/payments/classified";
export const editClassifiedPaymentUrl = (classifiedPaymentId) =>
  `api/payments/classified/${classifiedPaymentId}`;
export const addWorkOrderPaymentUrl = "api/payments/workOrder";
export const editWorkOrderPaymentUrl = (workOrderPaymentId) =>
  `api/payments/workOrder/${workOrderPaymentId}`;
export const paymentWorkOrderBalanceUrl = (workOrderDetailId) =>
  `api/payments/workOrder/balance?workOrderDetailId=${workOrderDetailId}`;
export const paymentUnclassifiedBalanceUrl = (unclassifiedId, chargeCodeId) =>
  `api/payments/unclassified/balance?unclassifiedId=${unclassifiedId}&chargeCodeId=${chargeCodeId}`;
export const addUnclassifiedPaymentUrl = "api/payments/unclassified";
export const editUnclassifiedPaymentUrl = (unclassifiedPaymentId) =>
  `api/payments/unclassified/${unclassifiedPaymentId}`;
export const paymentPerformbondBalanceUrl = (contractId, chargeCodeId) =>
  `api/payments/performbond/balance?contractId=${contractId}&chargeCodeId=${chargeCodeId}`;
export const addPerformbondPaymentUrl = "api/payments/performbond";
export const editPerformbondPaymentUrl = (performbondPaymentId) =>
  `api/payments/performbond/${performbondPaymentId}`;
export const paymentNetcostBalanceUrl = (netcostId, chargeCodeId) =>
  `api/payments/netcost/balance?netcostId=${netcostId}&chargeCodeId=${chargeCodeId}`;
export const addNetcostPaymentUrl = "api/payments/netcost";
export const editNetcostPaymentUrl = (netcostPaymentId) =>
  `api/payments/netcost/${netcostPaymentId}`;
export const paymentChangeorderBalanceUrl = (changeorderId, chargeCodeId) =>
  `api/payments/changeorder/balance?changeorderId=${changeorderId}&chargeCodeId=${chargeCodeId}`;
export const addChangeorderPaymentUrl = "api/payments/changeorder";
export const editChangeorderPaymentUrl = (changeorderPaymentId) =>
  `api/payments/changeorder/${changeorderPaymentId}`;
export const paymentFieldorderBalanceUrl = (fieldorderId, chargeCodeId) =>
  `api/payments/fieldorder/balance?fieldorderId=${fieldorderId}&chargeCodeId=${chargeCodeId}`;
export const addFieldorderPaymentUrl = "api/payments/fieldorder";
export const editFieldorderPaymentUrl = (fieldorderPaymentId) =>
  `api/payments/fieldorder/${fieldorderPaymentId}`;
export const addSupplementalPaymentUrl = "api/payments/supplemental";
export const editSupplementalPaymentUrl = (supplementalPaymentId) =>
  `api/payments/supplemental/${supplementalPaymentId}`;
export const paymentSupplementalBalanceUrl = (supplementalId) =>
  `api/payments/supplemental/balance?supplementalId=${supplementalId}`;
export const updateRetainageUrl = (paymentId) => `api/Payments/${paymentId}/Retainage`;
export const updateFinancialHistoryUrl = (contractId) => `api/Contracts/${contractId}/FinancialHistory`;
export const getReportServerUrlUrl = '/api/Reports/getReportServerURL';
export const getCYF_Year = '/api/Reports/getCYF_Year';
export const finalEvalExistsUrl = (contractId, vendorAddressId) =>
  `api/VendorEvaluations/FinalExists?contractId=${contractId}&vendorAddressId=${vendorAddressId}`;
export const contractVendorByContractAndVendorAddressIdsUrl = (contractId, vendorAddressId) =>
  `api/ContractVendors/ByContractAndVendorAddressIds?contractId=${contractId}&vendorAddressId=${vendorAddressId}`
export const getClassifiedPaymentInfoUrl = (paymentId) => `api/payments/${paymentId}/classified`;
export const getUnclassifiedPaymentInfoUrl = (paymentId) => `api/payments/${paymentId}/unclassified`;
export const getPerformbondPaymentInfoUrl = (paymentId) => `api/payments/${paymentId}/performbond`;
export const getFieldorderPaymentInfoUrl = (paymentId) => `api/payments/${paymentId}/fieldorder`;
export const getNetcostPaymentInfoUrl = (paymentId) => `api/payments/${paymentId}/netcost`;
export const getSupplementalPaymentInfoUrl = (paymentId) => `api/payments/${paymentId}/supplemental`;
export const getChangeorderPaymentInfoUrl = (paymentId) => `api/payments/${paymentId}/changeorder`;
export const getWorkorderPaymentsUrl = (paymentId) => `api/payments/${paymentId}/workorder`;
//export const getChangeorderPaymentByChangeOrder = (changeorderId ) => `api/payments/changeorder/ByChangeorder/${changeorderId}`;
